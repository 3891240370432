import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"

const IndexPage = () => (
<Layout>
  <div className="ui vertical center aligned segment">
    <h1>This website uses the following technologies and resources</h1>
    <ul>
      <li>
        <a href="../elegir-software-votacion-electronica-para-proceso-electoral/index.html">Cómo elegir el software de votación electrónica adecuado para un proceso electoral</a>
      </li>
      <li>
        <a href="../choose-right-online-voting-software-electoral-process/index.html">How to choose the right online voting software for an electoral process</a>
      </li>
      <li>
        <a href="../5-razones-elegir-voto-online/index.html">5 razones para elegir el voto online</a>
      </li>
      <li>
        <a href="../5-reasons-choose-online-voting/index.html">5 reasons to choose online voting</a>
      </li>
      <li>
        <a href="../anonymity-pseudonymity-e-participation/index.html">Anonymity, Pseudonymity and E-participation</a>
      </li>
      <li>
        <a href="../encoding-plaintexts-elgamal/index.html">Encoding plaintexts in ElGamal</a>
      </li>
      <li>
        <a href="../ballot-privacy-weighted-voting/index.html">Ballot privacy for weighted voting</a>
      </li>
      <li>
        <a href="../degree-privacy-weighted-voting/index.html">Degree of privacy for weighted voting</a>
      </li>
      <li>
        <a href="../privacy-attack-weighted-voting/index.html">A privacy attack on weighted voting</a>
      </li>
      <li>
        <a href="../degree-privacy-voting/index.html">Degree of privacy in voting</a>
      </li>
      <li>
        <a href="../election-methods-typed-classification/index.html">Election Methods: A typed classification</a>
      </li>
      <li>
        <a href="../anonymity-e-participation-pros-cons/index.html">Anonymity and e-participation: Pros and Cons</a>
      </li>
      <li>
        <a href="../3-crypto-schemes-liquid-democracy-iii/index.html">3 crypto schemes for liquid democracy (III)</a>
      </li>
      <li>
        <a href="index.html">Secure voting – a definition</a>
      </li>
      <li>
        <a href="../anonymous-authenticated-registration-e-participation/index.html">Anonymous authenticated registration for e-participation</a>
      </li>
      <li>
        <a href="../privacy-preserving-e-participation-nmix/index.html">Privacy-preserving e-participation with nMix</a>
      </li>
      <li>
        <a href="../elements-of-distributed-voting-system/index.html">Elements of a distributed voting system</a>
      </li>
      <li>
        <a href="../medidas-de-seguridad-en-la-i-votacion-ciudadana-de-madrid/index.html">Medidas de seguridad en la I Votación Ciudadana de Madrid</a>
      </li>
      <li>
        <a href="../parallelizing-a-mixnet-prototype-2/index.html">Parallelizing a mixnet prototype</a>
      </li>
      <li>
        <a href="../software-version-3-3-0-released/index.html">Software version 3.3.0 released</a>
      </li>
      <li>
        <a href="../a-general-ballot-encoding-method/index.html">A general ballot encoding method</a>
      </li>
      <li>
        <a href="../3-crypto-schemes-for-liquid-democracy-ii/index.html">3 crypto schemes for liquid democracy (II)</a>
      </li>
      <li>
        <a href="../roadmap-plans-for-distributed-web-voting/index.html">Roadmap & Plans for distributed web voting</a>
      </li>
      <li>
        <a href="../software-version-3-2-0-released/index.html">Software version 3.2.0 released</a>
      </li>
      <li>
        <a href="../a-typed-purely-functional-election/index.html">A typed purely functional election</a>
      </li>
      <li>
        <a href="../machine-executable-legal-code/index.html">Machine executable legal code</a>
      </li>
      <li>
        <a href="../multiplicative-vs-additive-homomorphic-elgamal/index.html">Multiplicative vs Additive Homomorphic ElGamal</a>
      </li>
      <li>
        <a href="../3-crypto-schemes-for-liquid-democracy-i/index.html">3 crypto schemes for liquid democracy (I)</a>
      </li>
      <li>
        <a href="../blockchains-and-bulletin-boards/index.html">Blockchains and bulletin boards</a>
      </li>
      <li>
        <a href="../uncertainty-information-and-cryptography/index.html">Uncertainty, information and cryptography</a>
      </li>
      <li>
        <a href="../a-typed-purely-functional-state-machine/index.html">A typed purely-functional state machine</a>
      </li>
      <li>
        <a href="../a-toy-model-of-information-theoretic-security/index.html">A toy model of information-theoretic security</a>
      </li>
      <li>
        <a href="../almost-150-000-people-vote-over-the-internet-with-nvotes/index.html">Almost 150.000 people vote over the Internet with nVotes</a>
      </li>
      <li>
        <a href="../a-mixnet-based-secure-voting-protocol/index.html">A mixnet-based secure voting protocol</a>
      </li>
      <li>
        <a href="../multi-ballot-elections/index.html">Multi-ballot elections</a>
      </li>
      <li>
        <a href="../hired-felix-robles-joins-our-team/index.html">Hired! Félix Robles joins our team!</a>
      </li>
    </ul>

  </div>
</Layout>
)

export default IndexPage
